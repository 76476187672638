export const data = [
	{
		section: 'Восстановление зуба пломбой',
		services: 
			[
				{
					name: 'Инфильтрационная анестезия (обезболивание)',
					cost: '500',
				},
				{
					name: 'Сошлифовывание твердых тканей зуба',
					cost: '600',
				},
				{
					name: 'Изоляция полости рта (постановка коффердама)',
					cost: '500',
				},
				{
					name: 'Пломба химического отверждения',
					cost: '1500',
				},
				{
					name: 'Пломба из фотополимеров',
					cost: '2000',
				},
				{
					name: 'Избирательное полирование зуба',
					cost: '400',
				},
				{
					name: 'Восстановление зуба пломбировочными материалами с использованием анкерных или стекловолоконных штифтов',
					cost: '3500',
				},

			],
	},
	{
		section : 'Лечение осложнений кариеса (эндодонтическое лечение корневых каналов)',
		services: [
			{
				name: 'Удаление внутриканального штифта/вкладки',
				cost: '1500',
			},
			{
				name: 'Наложение девитализирующей пасты',
				cost: '700',
			},
			{
				name: 'Пломбирование корневого канала зуба',
				cost: '600',
			},
			{
				name: 'Инструментальная и медикаментозная обработка корневого кананала',
				cost: '800',
			},
			{
				name: 'Временное пломбирование лекарственным препаратом корневого канала',
				cost: '600',
			},
			{
				name: 'Наложение временной пломбы',
				cost: '500',
			},
		]
	},
	{
		section : 'Профилактика',
		services: [
			{
				name: 'Профессиональное отбеливание зубов препаратом Опалесценс (2 челюсти в линии улыбки)',
				cost: '15000',
			},
			{
				name: 'Глубокое фторирование эмали зуба',
				cost: '300',
			},
			{
				name: 'Профессиональная гигиена полости рта и зубов',
				cost: '2500',
			},
			{
				name: 'Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области 1 зуба',
				cost: '200',
			},
		]
	},
	{
		section : 'Ортопедия. Несъемное протезирование',
		services: [
			{
				name: 'Восстановление зуба виниром, полукоронкой из материала Имакс или диоксид циркония с индивидуальной эстетикой',
				cost: '15 000',
			},
			{
				name: 'Восстановление зуба вкладкой из материала Имакс',
				cost: '12 000',
			},
			{
				name: 'Восстановление зуба коронкой временной прямым методом',
				cost: '1 000',
			},
			{
				name: 'Восстановление зуба коронкой временной композитной фрезерованной лабораторным методом',
				cost: '4 000',
			},
			{
				name: 'Восстановление зуба коронкой постоянной цельнометаллической',
				cost: '6 000',
			},
			{
				name: 'Восстановление зуба коронкой постоянной металлокерамической',
				cost: '9 000',
			},
			{
				name: 'Восстановление зуба коронкой постоянной безметалловой из диоксида циркония стандартная эстетика',
				cost: '12 000',
			},
			{
				name: 'Восстановление зуба коронкой постоянной безметалловой цельнокерамической диоксид циркония или Имакс с индивидуальной эстетикой	',
				cost: '15 000',
			},
			{
				name: 'Восстановление зуба коронкой постоянной металлокерамической под бюгельный протез',
				cost: '12 000',
			},
			{
				name: 'Восстановление зуба коронкой с использованием цельнолитой культевой вкладки',
				cost: '4 000',
			},
			{
				name: 'Изготовление коронки металлической штампованной',
				cost: '4 500',
			},			
		]
	},
	{
		section : 'Ортопедия. Съемное протезирование',
		services: [
			{
				name: 'Протезирование зубов полными съемными пластиночными протезами (индивидуальная ложка)',
				cost: '1 000',
			},
			{
				name: 'Протезирование зубов полными съемными пластиночными протезами с армированием',
				cost: '20 000',
			},
			{
				name: 'Протезирование частичными съемными пластиночными протезами для временного замещения 1-3 отсутствующих зубов (иммедиат-протез)',
				cost: '8 000',
			},
			{
				name: 'Протезирование частичными съемными пластиночными протезами с армированием (1 челюсть)',
				cost: '20 000',
			},
			{
				name: 'Протезирование съемными бюгельными протезами с кламмерной фиксацией (1 челюсть)',
				cost: '27 000',
			},
			{
				name: 'Протезирование съемными бюгельными протезами с замковой фиксацией (1 челюсть)',
				cost: '47 000',
			},
			{
				name: 'Протезирование съемными бюгельными протезами с телескопической фиксацией (1 челюсть)',
				cost: '45 000',
			},
			{
				name: 'Коррекция прикуса с использованием съемных и несъемных ортопедических конструкций — каппа',
				cost: '2 000',
			},			
		]
	},
	{
		section : 'Протезирование с опорой на имплантаты',
		services: [
			{
				name: 'Протезирование зуба с использованием имплантата коронкой постоянной безметалловой из диоксида циркония',
				cost: '22 500',
			},
			{
				name: 'Протезирование зуба с использованием имплантата коронкой постоянной металлокерамической',
				cost: '20 000',
			},
			{
				name: 'Протезирование зуба с использованием имплантата временной коронкой с винтовой или цементной фиксацией (1 единица)',
				cost: '6 000',
			},			
		]
	},
	{
		section : 'Прочие ортопедические услуги',
		services: [
			{
				name: 'Повторная фиксация на постоянный цемент несъемных ортопедических конструкций (1 единица)',
				cost: '1 000',
			},
			{
				name: 'Повторная фиксация на временный цемент несъемных ортопедических конструкций (1 единица)',
				cost: '500',
			},
			{
				name: 'Повторная фиксация на временный цемент несъемных ортопедических конструкций (all-on-six)',
				cost: '7 000',
			},
			{
				name: 'Повторная фиксация на временный цемент несъемных ортопедических конструкций (all-on-six)',
				cost: '1 000',
			},
			{
				name: 'Снятие несъемной ортопедической конструкции (all-on-six)',
				cost: '4 000',
			},
			{
				name: 'Перебазировка съемного протеза лабораторным методом',
				cost: '4 000',
			},
			{
				name: 'Приварка кламмера',
				cost: '2 000',
			},
			{
				name: 'Приварка зуба',
				cost: '2 000',
			},
			{
				name: 'Починка перелома базиса самотвердеющей пластмассой',
				cost: '2 000',
			},
			{
				name: 'Починка перелома/трещин базиса композитным материалом',
				cost: '1 500',
			},
			
		]
	},
	{
		section: 'Ортодонтия. Диагностика',
		services: [
			{
				name: 'Повторный прием врача-ортодонта (Комплексное обследование: анализ и расчет ОПТГ, ТРГ, диагностических моделей, цифровых фото, составление плана лечения)',
				cost: '2 500',
			},
			{
				name: 'Диагностика (цифровые фото, снятие слепков с 2 челюстей)',
				cost: '1 000',
			},
			{
				name: 'Сканирование зубных рядов',
				cost: '800',
			},
		]
	},
	{
		section: 'Ортодонтия. Брекеты',
		services: [
			{
				name: 'Ортодонтическая коррекция с применением брекет-системы частичной',
				cost: '16 000',
			},
			{
				name: 'Установка несъемного ретенционного аппарата (на одну челюсть)',
				cost: '7 000',
			},
			{
				name: 'Съемный стабилизирующий ретенционный аппарат после снятия брекетов-ортодонтическая каппа (на одну челюсть)',
				cost: '15 000',
			},
			{
				name: 'Ортодонтическая коррекция прикуса с применением самолигирующей брекет-системы металлической (Н4)',
				cost: '25 000',
			},
			{
				name: 'Фиксация брекет-системы на одну челюсть',
				cost: '10 000',
			},
			{
				name: 'Снятие брекет-системы с одной челюсти',
				cost: '9 000',
			},
			{
				name: 'Ортодонтическая коррекция с применением лигатурной брекет-системы металлической (Biomim)',
				cost: '22 000',
			},
			{
				name: 'Ортодонтическая коррекция с применением лигатурной брекет-системы комбинированной (Damon Clear\\Damon Q)',
				cost: '40 000',
			},
		]
	},
	{
		section: 'Прочие ортодонтические услуги',
		services: [
			{
				name: 'Ортодонтическая коррекция: повторная фиксация элемента брекет-системы (подклейка брекета)',
				cost: '1 500',
			},
			{
				name: 'Ортодонтическая коррекция прикуса с использованием межчелюстной тяги',
				cost: '300',
			},
			{
				name: 'Ортодонтическая коррекция с фиксацией искусственного зуба на дугу',
				cost: '3 400',
			},
			{
				name: 'Ортодонтическая коррекция несъемного ретейнера на 1 зуб',
				cost: '1 200',
			},
			{
				name: 'Ортодонтическая коррекция: активация брекет-системы (замена дуги, фиксация цепочки\\кнопки и т.д.) на одной челюсти',
				cost: '3 000',
			},
			{
				name: 'Коррекция съемного ортодонтического аппарата',
				cost: '3 000',
			},
			{
				name: 'Ремонт ортодонтического аппарата',
				cost: '1 200',
			},
		]
	},
	{
		section : 'Хирургические услуги',
		services: [
			{
				name: 'Наложение повязки при операциях в полости рта',
				cost: '500',
			},
			{
				name: 'Удаление зуба',
				cost: '1 100',
			},
			{
				name: 'Удаление зуба сложное с разъединением корней',
				cost: '2 700',
			},
			{
				name: 'Резекция верхушки корня',
				cost: '3 000',
			},
			{
				name: 'Вскрытие подслизистого или поднадкостничного очага воспаления в полости рта',
				cost: '500',
			},
			{
				name: 'Пластика альвеолярного отростка',
				cost: '3 000',
			},
			{
				name: 'Операция удаления ретинированного, дистопированного или сверхкомплектного зуба',
				cost: '5000',
			},
			{
				name: 'Открытый кюретаж при заболеваниях пародонта в области зуба',
				cost: '500',
			},
			{
				name: 'Закрытый кюретаж при заболеваниях пародонта в области зуба',
				cost: '500',
			},
			{
				name: 'Лоскутная операция в полости рта',
				cost: '5 000',
			},
			{
				name: 'Костная пластика челюстно-лицевой области',
				cost: '25 000',
			},
			{
				name: 'Пластика уздечки верхней губы',
				cost: '1 000',
			},
			{
				name: 'Пластика уздечки нижней губы',
				cost: '1 000',
			},
			{
				name: 'Пластика уздечки языка',
				cost: '1 000',
			},
			{
				name: 'Вестибулопластика',
				cost: '5 000',
			},
			{
				name: 'Синус-лифтинг (костная пластика, остеопластика)',
				cost: '25 000',
			},
			{
				name: 'Лечение перикоронита (промывание, рассечение и/или иссечение капюшона)',
				cost: '1 100',
			},
			{
				name: 'Гингивопластика',
				cost: '5 000',
			},
			{
				name: 'Пластика перфорации верхнечелюстной пазухи',
				cost: '1 000',
			},
			{
				name: 'Наложение шва на слизистую оболочку рта',
				cost: '500',
			},

			
		]
	},
	{
		section : 'Имплантация',
		services: [
			{
				name: 'Внутрикостная дентальная имплантация временного имплантата или мини-имплантата',
				cost: '15 000',
			},
			{
				name: 'Внутрикостная дентальная имплантация ортодонтического имплантата',
				cost: '5 000',
			},
			{
				name: 'Внутрикостная дентальная имплантация',
				cost: '22 500',
			},
			{
				name: 'Внутрикостная дентальная имплантация AnyRidge',
				cost: '30 000',
			},			
		]
	}
];