import styles from './reviews.module.scss'

function Reviews() {
    return (
        <div>
            
        </div>
    )
}

export default Reviews;