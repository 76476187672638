import carries from '../../assets/images/carries.png';
import plomb from '../../assets/images/plomb.png';
import check_tooth from '../../assets/images/check-tooth.png';
import metal_braces from '../../assets/images/metal-braces.png';
import ceramic_braces from '../../assets/images/ceramic-braces.png';
import lingual_braces from '../../assets/images/lingual-braces.png';
import removal_prosthetics from '../../assets/images/removal-prosthetics.png';
import fixed_prosthetics from '../../assets/images/fixed-prosthetics.png';
import implant_prosthetics from '../../assets/images/implant-prosthetics.png';
import surgery from '../../assets/images/surgery.png';
import anyridge_implant from '../../assets/images/anyridge-implant.png';
import others_image from '../../assets/images/others.png';

export const data = {
	dental_treatment: [
		{
			title: 'Лечение кариеса',
			description: 'Вылечим кариес на изи ваще',
			img: carries,
			description:
				'Кариес – это результат повреждения зубной ткани под воздействием бактерий, что требует удаления пораженных участков для сохранения зуба. Наша стоматологическая клиника предоставляет высококачественное лечение зубов с кариесом и эффективное пломбирование уже обработанных зубов. Мы гарантируем качественную помощь и заботу о вашем улыбке.',
		},
		{
			title: 'Восстановление зуба пломбой',
			description:
				'Восстановление зуба пломбой – популярная процедура стоматологии. Применяется при обнаружении кариеса, особенно на ранних стадиях, если разрушение зуба небольшое. Процесс включает удаление пораженной ткани и заполнение пломбой, восстанавливая эстетику и функцию зуба.',
			img: plomb,
		},
		{
			title: 'Профилактика',
			description:
				'Профилактика и лечение стоматологических заболеваний, а также поддержание гигиены полости рта, играют ключевую роль в здоровье как взрослых, так и детей. Эффективная профилактика требует комплексного подхода, который предотвращает развитие серьезных патологий и длительных лечебных процедур. Ухаживать за полостью рта важно и дома, и в стоматологической клинике, так как не все меры могут быть выполнены самостоятельно.',
			img: check_tooth,
		},
	],
	orthodontics: [
		{
			title: 'Металлические бреккеты',
			description:
				'Металлические брекеты - это надежное и эффективное решение для коррекции зубных аномалий. Изготовленные из прочных материалов, они обеспечивают точное позиционирование зубов. Преимущество металлических брекетов заключается в их надежности, быстрой коррекции и доступной стоимости.',
			img: metal_braces,
		},
		{
			title: 'Керамические брекеты',
			description:
				'Керамические брекеты - это эстетичное решение для поправки зубов. Изготовленные из материала, схожего с цветом зубов, они практически незаметны. Керамические брекеты обеспечивают эффективную коррекцию при минимальном воздействии на внешний вид, делая улучшение улыбки комфортным.',
			img: ceramic_braces,
		},
		{
			title: 'Лингвальные брекеты',
			description:
				'Лингвальные брекеты - инновационное решение для тех, кто ценит невидимость коррекции. Размещенные на внутренней стороне зубов, они остаются незаметными в повседневной жизни. Лингвальные брекеты обеспечивают точную коррекцию без влияния на внешний облик, позволяя достичь желаемого результата незаметно для окружающих.',
			img: lingual_braces,
		},
	],
	prosthetics: [
		{
			title: 'Съемные протезы',
			description:
				'Съемные зубные протезы – легкое и удобное решение для восстановления жевательной функции. Снять и надеть их можно самостоятельно, без помощи специалиста - это обеспечивает комфорт и свободу в повседневной жизни. Это практичное решение, обеспечивающее хорошую гигиеничностью.',
			img: removal_prosthetics,
		},
		{
			title: 'Несъемные протезы',
			description:
				'Несъемное протезирование зубов - это создание искусственных заменителей утраченных и поврежденных зубов. Искусственные конструкции создаются из качественных и прочных материалов, после чего надежно фиксируются на опорных зубах или вживленных имплантах',
			img: fixed_prosthetics,
		},
		{
			title: 'Протезирование на имплантах',
			description:
				'Микропротезы на имплантах используются для улучшения внешнего вида зубов: они помогают изменить форму, цвет и положение поврежденных зубов, не прибегая к сложным вмешательствам.',
			img: implant_prosthetics,
		},
	],
	surgery: [
		{
			title: 'Хирургические услуги',
			description:
				'Благодаря современным медицинским технологиям, которые используются в нашей клинике, хирургические вмешательства проводятся безболезненно и малотравматично. Мы приложим максимум усилий, чтобы сохранить «родные» зубы пациента – удаление проводится только в самом крайнем случае.',
			img: surgery,
		},
		{
			title: 'Удаление зубов различной сложности',
			description: 'Мы всегда стараемся спасти больной зуб и прибегаем к удалению в крайних случаях, но при необходимости - делаем это качественно и грамотно. Благодаря огромному опыту, наши врачи могут удалить зубы быстро, безболезненно и без негативных последствий' 
		},
		{
			title: 'Закрытие рецессий',
			description: 'Закрытие рецессии десны - это хирургическая операция по трансплантации тканей, взятых у пациента, в область дефекта с целью воссоздания естественного контура здоровых десен.'
		}
	],
	implanting: [
		{
			title: 'Пластика кости и мягких тканей',
			description: 'Костная пластика позволяет восстановить, недостающий объем костной ткани или десны. Данный метод используется для исправления последствий травм, атрофических процессов челюсти и врожденных деформаций лица.'
		},
		{
			title: 'Тотальная реабилитация с применением имплантационных систем',
			description: 'Имплантация - внедрение искусственного зуба в виде корня, интегрированного в кость. Главное преимущество - сохранение полной функциональности и равномерноое распределение нагрузки, в отличие от мостов и съемных протезов.',
		},
		{
			title: 'Установка имплантатов различных конструкций',
			description: 'Мы работаем с имплантами различных конструкций. Наши специалисты помогут вам с выбором и проведут имплантацию качественно с гарантией.',
		}
	],
	others: [{ title: 'Прочие ортопедические услуги', description: 'Наша клиника предоставляет широкий спектр ортопедических стоматологических услуг, включая нестандартные. Если вы не смогли подобрать для себя услугу - оставьте заявку и опишите вашу ситуацию, наши специалист свяжутся с вами и сделают всё, чтобы вам помочь!', img: others_image }],
};
export const titles = [
	'Все услуги',
	'Лечение зубов',
	'Ортодонтия',
	'Протезирование',
	'Имплантация',
	'Хирургия',
	'Прочие услуги',
];
export const all = [
	...data.dental_treatment,
	...data.orthodontics,
	...data.prosthetics,
	...data.surgery,
	...data.implanting,
	...data.others,
];
